<template>
  <div>
    <template v-if="transitionId != null">
      <v-card target="_blank" :href="transitionId" outlined>
        <v-container fluid class="d-flex align-center justify-content-between">
          <h2 class="mr-1">ادفع بأستخدام</h2>
          <v-img contain height="100" src="@/assets/zaincash.png"></v-img>
        </v-container>
      </v-card>
    </template>
  </div>
</template>

<script>
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    oldData: {
      default: () => [],
    },
    field: {
      type: Object,
      default: () => {},
    },
    formId: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      transitionId: null,
      connection: null,
      ready: false,
    };
  },
  methods: {
    connectToSocket() {
      this.$socket.emit("connectToSocket", {
        formId: this.formId,
        fieldId: this.field.id,
      });
    },
  },
  mounted() {
    this.connection
      .on("transition_" + this.$route.params.orderNodeId)
      .then((data) => {
        console.log(data);
      });
  },
  async created() {
    try {
      var res = await this.$http.post("/zaincash", {
        data: "string",
        nodeId: this.$route.params.nodeId,
        fieldId: this.field.id,
        orderNodeId: this.$route.params.orderNodeId,
        workflowId: this.$route.params.workflowId,
        formId: this.formId,
      });
      this.transitionId = res.data.result;
    } catch (e) {
      console.log(e);
    }
    this.connection = new HubConnectionBuilder()
      .withUrl(`${this.$config.BASE_URL_PROD}/SignalRHub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Error)
      .build();
    this.connection.Credentials = true;
    this.connection
      .start()
      .then(() => {
        console.log("connected");
        this.ready = true;
      })
      .catch((err) => {
        console.error("ss", err);
      });
    // this.selected = this.oldData;
  },
  // watch: {
  //   ready() {
  //     if (this.ready) {
  //     }
  //   },
  // },
};
</script>

<style></style>
