<template>
  <div>
    <v-card outlined>
      <v-card-title>
        <h4 class="mb-2">
          {{ field.field.title }}
          <span class="required" v-if="field.isRequired">*</span>
        </h4>
        <v-spacer></v-spacer>
        <v-btn
        :disabled="$route.params.actionType == 'view'"
          color="primary"
          class="text-none mr-2 btn"
          :loading="$global.state.uploading"
          @click="onButtonClick(index)"
          large
        >
          <v-icon left> mdi-file </v-icon>
          <h3>{{ $t("select") }}</h3>
        </v-btn>
        <input
          :ref="`uploader${index}`"
          class="d-none"
          :rules="field.isRequired == true ? [$global.state.required()] : []"
          type="file"
          accept="jpg, jpeg, png, mp4, mp3, pdf, doc, docx, xls, xlsx,
                          ppt, pptx, txt, zip, rar"
          @change="(e) => onFileChanged(e, index)"
        />
        <v-text-field
          name="name"
          label="label"
          id="id"
          class="d-none"
          v-model="data"
          :rules="isRequired == true ? [$global.state.required()] : []"
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-card class="my-2" outlined v-if="file.name">
          <v-card-title>
            {{ file.name }}
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="text-none mr-2 btn"
              :loading="loading"
              :disabled="loading || chuncks.length == 0"
              @click="uploadChuncks()"
              large
            >
              <v-icon left> cloud_upload </v-icon>
              {{ $t('upload') }}
            </v-btn>
            <v-container fluid>
              <v-alert v-if="showAlert" :value="true" dark>
                <div class="d-flex align-center">
                  <h3>{{ $t('resum') }}</h3>
                  <v-spacer></v-spacer>
                  <v-btn color="green" @click="createChunck(true)">{{ $t('yes') }}</v-btn>
                  <v-btn color="error" class="mx-2" @click="createChunck(false)"
                    >{{ $t('no') }}</v-btn
                  >
                </div>
              </v-alert>
              <v-alert v-if="finished" type="success" :value="true">
                <v-icon>mdi-check</v-icon>
                <span>
                  {{ $t('upload-success') }}
                  </span>
              </v-alert>

              <h6>{{ $t('progress') }} :</h6>
              <v-progress-linear
                v-if="chuncks.length > 0"
                class="mt-2"
                height="4px"
                :value="(100 * this.totalUploaded) / file.size"
              ></v-progress-linear>

              <div></div>
            </v-container>
          </v-card-title>
        </v-card>
        <v-alert color="secondary" text outlined :value="true">
          <div class="d-flex align-center">
            <v-icon color="primary">mdi-information-outline</v-icon>
            <h4 class="mx-2" style="padding: 0">
              <span class="font-weight-bold"> {{ $t('allowed-files') }}: </span>
              jpg, jpeg, png, mp4, mp3, pdf, doc, docx, xls, xlsx, ppt, pptx,
              txt, zip, rar.
            </h4>
          </div>
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    isRequired: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    field: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      data: "",
      chunkSize: 50000000,
      file: {
        size: 0,
      },
      totalChunks: 0,
      chuncks: [],
      current: 0,
      uploadId: "",
      loading: false,
      isOld: false,
      finished: false,
      totalUploaded: 0,
      showAlert: false,
      uploadedParts: [],
    };
  },
  methods: {
    onButtonClick(index) {
      this.$refs[`uploader${index}`].click();
    },
    onFileChanged(e) {
      this.file = e.target.files[0];
      this.checkFile();
    },
    checkFile() {
      let file = this.file;
      this.totalUploaded = 0;
      this.finished = false;
      let old = {
        file: "",
      };
      if (localStorage.getItem("chuncks")) {
        old = JSON.parse(localStorage.getItem("chuncks"));
      }
      if (old.file == file.name) {
        this.isOld = true;
        this.showAlert = true;
        this.totalUploaded = old.totalUploaded;
      } else {
        this.isOld = false;
        this.showAlert = false;
        this.createChunck(false);
      }
    },
    bytesToSize(x) {
      const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let l = 0,
        n = parseInt(x, 10) || 0;

      while (n >= 1024 && ++l) {
        n = n / 1024;
      }

      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
    },
    createChunck(isOld) {
      let file = this.file;
      this.showAlert = false;
      this.chuncks = [];
      var numberofChunks = Math.ceil(file.size / this.chunkSize);
      this.totalChunks = numberofChunks;
      let old;
      if (localStorage.getItem("chuncks")) {
        old = JSON.parse(localStorage.getItem("chuncks"));
      }

      let from = 0;
      for (let i = from; i < numberofChunks; i++) {
        let start = i * this.chunkSize;
        let end = Math.min(start + this.chunkSize, file.size);
        let chunk = file.slice(start, end);
        var part = {
          name: file.name,
          size: chunk.size,
          chunk: i,
          total: numberofChunks,
          file: chunk,
          finished: false,
          uploaded: 0,
        };
        this.chuncks.push(part);
        if (!isOld) {
          this.uploadedParts.push(part);
        }
      }
      if (isOld) {
        let loadedParts = localStorage.getItem("parts");
        if (loadedParts) {
          loadedParts = JSON.parse(loadedParts);
          for (let i = 0; i < loadedParts.length; i++) {
            this.uploadedParts.push(loadedParts[i]);
          }
        }
        this.totalUploaded = this.uploadedParts
          .map((x) => x.uploaded)
          .reduce((a, b) => a + b);
        this.current = this.uploadedParts.findIndex((x) => x.finished == false);
        this.chuncks.find((x) => x.chunk == this.current).file = this.chuncks
          .find((x) => x.chunk == this.current)
          .file.slice(
            this.uploadedParts[this.current].uploaded,
            this.chuncks.find((x) => x.chunk == this.current).file.size
          );
        this.uploadId = JSON.parse(localStorage.getItem("id")).id;
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async uploadChuncks() {
      this.loading = true;
      this.current = 0;
      if (!localStorage.getItem("chuncks")) {
        localStorage.setItem(
          "chuncks",
          JSON.stringify({
            id: this.uploadId,
            part: this.current,
            finished: false,
            file: this.file.name,
          })
        );
      }
      let chuncks = JSON.parse(localStorage.getItem("chuncks"));
      try {
        for await (let file of this.chuncks) {
          var formData = new FormData();
          await formData.append("file", new File([file.file], file.name, {}));
          await formData.append("Index", this.current);
          await formData.append("Total", this.chuncks.length);
          if (this.current != 0) {
            formData.append("Id", this.uploadId);
          } else {
            if (this.isOld) {
              formData.append("Id", chuncks.id);
            } else {
              formData.append("Id", "");
            }
          }
          await formData.append("Extension", "." + file.name.split(".").pop());
          await formData.append("Name", file.name);

          let response = await this.$http.post("/UploadFile/chunk", formData, {
            maxContentLength: 100000000,
            maxBodyLength: 1000000000,
            onUploadProgress: (progressEvent) => {
              this.uploadedParts[this.current].uploaded = progressEvent.loaded;
              this.totalUploaded = this.uploadedParts
                .map((x) => x.uploaded)
                .reduce((a, b) => a + b);
              console.log(this.totalUploaded);
              chuncks = JSON.parse(localStorage.getItem("chuncks"));
              localStorage.setItem(
                "chuncks",
                JSON.stringify({
                  id: this.uploadId,
                  part: this.current,
                  finished: false,
                  file: this.file.name,
                  fileSize: this.file.size,
                  uploadedPartSize: progressEvent.loaded,
                  totalUploaded: this.uploadedParts
                    .map((x) => x.uploaded)
                    .reduce((a, b) => a + b),
                })
              );
              chuncks = JSON.parse(localStorage.getItem("chuncks"));
              this.chuncks.find((v) => v.part == file.part).uploaded =
                progressEvent.loaded;
              localStorage.setItem("parts", JSON.stringify(this.chuncks));
            },
          });
          this.uploadId = response.data.id;
          if (this.current == this.chuncks.length - 1) {
            chuncks.finished = true;
            this.data = response.data.path;
            this.loading = false;
            this.$emit("input", this.data);
            this.finished = true;
            localStorage.setItem(
              "chuncks",
              JSON.stringify({
                file: "",
                id: "",
              })
            );
          }
          this.chuncks.find((v) => v.part == file.part).finished = true;
          localStorage.setItem("parts", JSON.stringify(this.chuncks));
          this.current++;
          chuncks.part = this.current;
        }
      } catch (e) {
        console.log(e);
      }

      //   this.loading = false;
    },
  },
};
</script>
