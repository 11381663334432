<template>
  <div>
    <v-autocomplete
      v-model="selected"
      :rules="isRequired == true ? [$global.state.required()] : []"
      :multiple="multiple"
      chips
      
      :placeholder="title"
      filled
      outlined
      @input="onInput"
      :items="items"
      hide-details="auto"
      required
    />
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    oldData: {
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  created() {
    if(this.oldData.length > 0) {
      this.selected = this.oldData;
    }
    // this.selected = this.oldData;
  },
  methods: {
    onInput(value) {
      this.$emit("input", Array.isArray(value) ? value.join(',') : value);
    },
  },
};
</script>
