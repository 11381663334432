<template>
  <div>
    <v-dialog
      ref="dialog"
      v-model="dialog"
      :return-value.sync="dateDialog"
      persistent
      width="290px"
    >
      <v-date-picker color="primary" :locale="$i18n.locale" v-model="dateDialog" scrollable>
        <v-spacer></v-spacer>

        <v-btn text color="primary" @click="$refs.dialog.save(dateDialog)">{{ $t("save") }}</v-btn>
      </v-date-picker>
    </v-dialog>
    <div
      class="v-input v-input--hide-details v-input--dense v-text-field v-text-field--single-line v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder"
    >
      <div class="v-input__control">
        <div class="v-input__slot align-center">
          <div class="v-input__prepend-outter ml-4 mt-2">
            <div class="v-input__icon v-input__icon--prepend-outter">
              <i
                @click="dialog = true"
                aria-hidden="true"
                class="v-icon notranslate mdi mdi-calendar"
              ></i>
            </div>
          </div>
          <fieldset aria-hidden="true">
            <legend>
              <span>&ZeroWidthSpace;</span>
            </legend>
          </fieldset>
          <div class="v-text-field__slot py-2">
            <v-text-field
              outlined
              dense
              hide-details="auto"
              v-mask="'##'"
              :placeholder="$t('day')"
              v-model="date.day"
              @input="
                (v) => {
                  v > 31 ? (date.day = 31) : v;
                  if (v.length == 2) {
                    if ($refs.month !== undefined) {
                      $refs.month.focus();
                    }
                  }
                }
              "
              :rules="rules"
            ></v-text-field>
            <span class="mx-2">-</span>
            <v-text-field
              outlined
              dense
              hide-details="auto"
              v-mask="'##'"
              v-model="date.month"
              :rules="rules"
              ref="month"
              @input="
                (v) => {
                  v > 12 ? (date.month = 12) : v;
                  if (v.length == 2) {
                    if ($refs.year !== undefined) {
                      $refs.year.focus();
                    }
                  }
                }
              "
              :placeholder="$t('month')"
            ></v-text-field>
            <span class="mx-2">-</span>
            <v-text-field
              outlined
              dense
              hide-details="auto"
              v-mask="'####'"
              :rules="rules"
              v-model="date.year"
              ref="year"
              @input="
                (v) => {
                  v > new Date().getFullYear()
                    ? (date.year = new Date().getFullYear())
                    : (date.year = v);
                }
              "
              :placeholder="$t('year')"
            ></v-text-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    rules: {},
    oldData: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      date: { year: "", month: "", day: "" },
      dateDialog: new Date().toISOString().slice(0, 10)
    };
  },
  created() {
    let date = new Date();
    if (this.oldData != null) {
      date = new Date(this.oldData);
    }
    this.date.year = date.getFullYear();
    this.date.month = date.getMonth() + 1;
    this.date.month =
      this.date.month < 10 ? "0" + this.date.month : this.date.month.toString();
    this.date.day = date.getDate();
    this.date.day =
      this.date.day < 10 ? "0" + this.date.day : this.date.day.toString();
    date = moment(
      `${this.date.year}-${this.date.month}-${this.date.day}`
    ).format("yyyy-MM-DD");
    if (moment(date).isValid()) {
      this.$emit("input", date);
    } else {
      console.error("Error! Invalid date");
    }
  },
  watch: {
    date: {
      handler(date) {
        let date1 = moment(
          `${this.date.year}-${this.date.month}-${this.date.day}`
        ).format("yyyy-MM-DD");
        this.$emit("input", date1);
      },
      deep: true
    },
    oldData: {
      handler(date) {
        date = new Date(date);
        this.date.year = date.getFullYear();
        this.date.month = date.getMonth() + 1;
        this.date.month =
          this.date.month < 10
            ? "0" + this.date.month
            : this.date.month.toString();
        this.date.day = date.getDate();
        this.date.day =
          this.date.day < 10 ? "0" + this.date.day : this.date.day.toString();
        date = moment(
          `${this.date.year}-${this.date.month}-${this.date.day}`
        ).format("yyyy-MM-DD");
        if (moment(date).isValid()) {
          this.$emit("input", date);
        } else {
          console.error("Error! Invalid date");
        }
      },
      deep: true
    },
    dateDialog: {
      handler(date) {
        //Make date a date object
        date = new Date(date);
        //Get the year, month, and day from the date object
        this.date.year = date.getFullYear().toString();
        this.date.month = date.getMonth() + 1;
        this.date.month =
          this.date.month < 10
            ? "0" + this.date.month
            : this.date.month.toString();
        this.date.day = date.getDate().toString();
        this.date.day =
          this.date.day < 10 ? "0" + this.date.day : this.date.day.toString();
      },
      deep: true
    }
  }
};
</script>
