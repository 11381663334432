<template>
    <v-card outlined>
      <v-card-title>
        <h4 class="mb-2">
          {{ field.field.title }}
          {{ field.field.data }}
          <span class="required" v-if="field.isRequired">*</span>
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="$route.params.actionType == 'view'"
          color="primary"
          class="mr-2 btn"
          :loading="uploading"
          @click="acquireImage(index)"
          large
        >
          <v-icon left>mdi-printer</v-icon>
          <h3>من السكنر</h3>
        </v-btn>
        <v-btn
          :disabled="$route.params.actionType == 'view'"
          color="primary"
          class="mr-2 btn"
          :loading="uploading"
          @click="onButtonClick(index)"
          large
        >
          <v-icon left>cloud_upload</v-icon>
          <h3>رفع ملف</h3>
        </v-btn>
        <input
          :ref="`uploader${index}`"
          class="d-none"
          :rules="field.isRequired ? [$global.state.required()] : []"
          type="file"
          multiple
          accept="jpg, jpeg, png, mp4, mp3, pdf, doc, docx, xls, xlsx, ppt, pptx, txt, zip, rar"
          @change="(e) => onFileChanged(e, index)"
        />
        <v-text-field
          name="name"
          label="label"
          id="id"
          class="d-none"
          :rules="field.isRequired == true ? [uploaded.length > 0] : []"
        ></v-text-field>
      </v-card-title>
      <v-card-subtitle class="mt-3">
        <v-alert color="secondary" text outlined :value="true">
          <div class="d-flex align-center">
            <v-icon color="primary">mdi-information-outline</v-icon>
            <h4 class="mx-2" style="padding: 0">
              <span class="font-weight-bold">{{ $t("allowed-files") }}:</span>
              jpg, jpeg, png, mp4, mp3, pdf, doc, docx, xls, xlsx, ppt, pptx, txt,
              zip, rar.
            </h4>
          </div>
        </v-alert>
      </v-card-subtitle>
      <v-card-text v-if="uploaded.length > 0">
        <div>
          <v-card
            outlined
            v-for="(up, i) in uploaded"
            :key="`uploadedFile${i}`"
            @click="preview(up)"
          >
            <v-card-title>
              <v-row class="ma-2">
                <v-icon :color="getFileIconColor(up)" class="mx-2">{{
                  getFileIcon(up)
                }}</v-icon>
                <span dir="ltr">{{ up.split("/").pop().split('_').pop() }}</span>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="$route.params.actionType == 'view'"
                  color="error"
                  @click.stop="remove(i)"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-row>
            </v-card-title>
          </v-card>
          <v-dialog v-model="previewDialog" fullscreen>
            <v-card>
              <v-toolbar color="secondary" dark outlined>
                <v-btn icon @click="closePreviewDialog">
                  <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title dir="ltr">{{ getFileNameFromPath(previewFile) }}</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <template v-if="previewFile.endsWith('.pdf')">
                  <iframe
                    :src="$config.BASE_URL_PROD + '/' + previewFile"
                    width="100%"
                    height="850"
                  ></iframe>
                </template>
                <template v-else-if="isImage(previewFile)">
                  <v-img :src="$config.BASE_URL_PROD + '/' + previewFile" contain></v-img>
                </template>
                <template v-else>
                  <v-btn color="primary" :href="$config.BASE_URL_PROD + '/' + previewFile" download>
                    <v-icon left>cloud_download</v-icon>
                    {{ $t('download-file') }}
                  </v-btn>
                </template>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </v-card-text>
    </v-card>
  </template>
  

<script>
export default {
    props: {
        isRequired: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
        field: {
            type: Object,
            default: () => ({}),
        },
        returnArray: {
            type: Boolean,
            default: false,
        },
        existingData: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            uploaded: this.existingData ? this.existingData.split(',') : [],
            uploading: false,
            previewDialog: false, // whether preview dialog is open or not
            previewFile: '', // the file to preview
        };
    },
    // mounted() {
    //   if (this.existingData) {
    //     this.uploaded = this.existingData.split(',');
    //   }
    // },

    methods: {
        closePreviewDialog() {
      this.previewDialog = false;
    },

    getFileNameFromPath(filePath) {
      return filePath.split('/').pop().split('_').pop();
    },
        isImage(file) {
            const extensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
            const ext = file.split('.').pop().toLowerCase();
            return extensions.includes(ext);
        },
        getFileIcon(file) {
            const ext = file.split('.').pop().toLowerCase();
            switch (ext) {
                case 'pdf':
                    return 'fi fi-rr-file-pdf text-red-500';
                case 'doc':
                case 'docx':
                    return 'fi fi-rr-file-word text-blue-500';
                case 'xls':
                case 'xlsx':
                    return 'fi fi-rr-file-excel text-green-500';
                case 'ppt':
                case 'pptx':
                    return 'fi fi-rr-file-powerpoint text-orange-500';
                case 'txt':
                    return 'fi fi-rr-file-text text-gray-500';
                case 'zip':
                case 'rar':
                    return 'fi fi-rr-file-zip text-purple-500';
                case 'mp3':
                    return 'fi fi-rr-file-music text-pink-500';
                case 'mp4':
                    return 'fi fi-rr-film-strip text-indigo-500';
                default:
                    return 'fi fi-rr-file text-gray-500';
            }
        },

        getFileIconColor(file) {
            const ext = file.split('.').pop().toLowerCase();
            switch (ext) {
                case 'pdf':
                    return '#F44336';
                case 'doc':
                case 'docx':
                    return '#2196F3';
                case 'xls':
                case 'xlsx':
                    return '#4CAF50';
                case 'ppt':
                case 'pptx':
                    return '#9C27B0';
                case 'txt':
                    return '#607D8B';
                case 'zip':
                case 'rar':
                    return '#FFC107';
                case 'mp3':
                    return '#FF5722';
                case 'mp4':
                    return '#E91E63';
                default:
                    return '#9E9E9E';
            }
        },

        preview(file) {
            this.previewFile = file;
            if (!this.isImage(file) && !file.endsWith('.pdf')) {
                window.location.href = `${this.$config.BASE_URL_PROD}/${file}`;
            } else {
                this.previewDialog = true;
            }
        },
        acquireImage(index) {
            console.log(
                window.scanner.scan(this.displayImagesOnPage, {
                    output_settings: [{
                        type: "return-base64",
                        format: "jpg",
                    }, ],
                })
            );
            this.scanIndex = index;
        },

        displayImagesOnPage(successful, mesg, response) {
            if (!successful) {
                console.error("Failed: " + mesg);
                return;
            }

            if (
                successful &&
                mesg != null &&
                mesg.toLowerCase().indexOf("user cancel") >= 0
            ) {
                console.info("User canceled");
                return;
            }

            var scannedImages = window.scanner.getScannedImages(
                response,
                true,
                false
            );
            for (
                var i = 0; scannedImages instanceof Array && i < scannedImages.length; i++
            ) {
                var scannedImage = scannedImages[i];
                this.processScannedImage(scannedImage);
            }
        },

        async processScannedImage(scannedImage) {
            this.imagesScanned.push(scannedImage.src);
            var img = document.createElement("img");
            img.classList.add("scanned");
            img.width = "300px";
            img.height = "500px";
            img.src = scannedImage.src;
            var arr = scannedImage.src.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            console.log(u8arr);
            let file = new Blob([u8arr], {
                type: mime
            });
            file = new File([file], "Signature.png", {
                type: "image/png",
            });
            await this.handleFileUpload({
                file: [file],
                endPoint: "UploadFile",
                index: this.scanIndex,
            });
        },

        remove(i) {
            this.uploaded.splice(i, 1);
            let ret = this.returnArray ? this.uploaded : this.uploaded.join(",");
            this.$emit("input", ret);
        },

        onFileChanged(e, index) {
        const filesToAdd = [];
        const existingFiles = this.uploaded.map((file) =>
            file.split("/").pop().split("_").pop()
        );

        for (let i = 0; i < e.target.files.length; i++) {
            const fileName = e.target.files[i].name;
            if (!existingFiles.includes(fileName)) {
            filesToAdd.push(e.target.files[i]);
            }
        }

        this.handleFileUpload({
            file: filesToAdd,
            endPoint: "UploadFile",
            index: index,
        }).then(() => {
            // Reset the file input after processing the selected files
            e.target.value = null;
        });
        },


        onButtonClick(index) {
            // this.isSelecting = true
            window.addEventListener(
                "focus",
                () => {
                    // this.isSelecting = false
                }, {
                    once: true,
                }
            );

            // this.uploading = true;
            this.$refs[`uploader` + index].click();

        },
        async handleFileUpload({
            file,
            endPoint,
            index
        }) {
            return new Promise((resolve, reject) => {
                try {
                    this.uploading = true;
                    let formData = new FormData();
                    for (let i = 0; i < file.length; i++) {
                        formData.append("files", file[i]);
                    }
                    this.$http
                        .post(endPoint, formData)
                        .then((res) => {
                            for (let i = 0; i < res.data.fileList.length; i++) {
                                this.uploaded.push(res.data.fileList[i].path);
                            }
                            // this.$global.state.path = res.data.fileList[0].path;

                            let ret = this.returnArray ? this.uploaded : this.uploaded.join(",");
                            console.log("ret");
                            console.log(ret);
                            this.$emit("input", ret);
                            this.$service.success(this.$t("upload-success"));
                            resolve();

                            this.uploading = false;
                        })
                        .catch((err) => {
                            console.log(err);

                            this.uploading = false;
                            this.$service.faild("حدث خطأ اثناء الرفع");
                            reject();
                        })
                        .finally(() => {
                            this.uploading = false;
                        });
                } catch (err) {
                    console.log("ss", err);
                    this.$service.faild("حدث خطأ اثناء الرفع");

                    this.uploading = false;
                    reject();
                }
            });
        },

    },
    created() {
        this.$emit("input", this.currentValue)
    },
};
</script>
