<template>
  <div>
    <div
      style="border: 1px solid #a1a1a1; border-radius: 5px"
      @mouseup="changeFun(index)"
    >
      <VueSignaturePad
        :options="{ penColor: penColorValue }"
        height="150px"
        ref="signaturePad"
      />
    </div>
    <v-card-actions>
      <!-- <v-btn class="mt-n6" color="" @click="save()"> save</v-btn> -->
      <v-btn outlined class="mt-n6" color="" @click="undo(index)">
        {{ $t('alrjwa-khtwh') }}
        </v-btn
      >
      <v-btn outlined class="mt-n6" color="" @click="clear(index)">
        {{ $t('clear-all') }}
        </v-btn
      >

      <v-spacer></v-spacer>
      <v-select
        @change="changePenColor()"
        class="pt-2"
        v-model="penColorValue"
        :items="penColors"
        dense
        :label="$t('lwn-alkht')"
        append-icon="mdi-pen"
      ></v-select>
    </v-card-actions>
  </div>
</template>
<script>
import Vue from "vue";
import { VueSignaturePad } from "vue-signature-pad";
Vue.use(VueSignaturePad);
export default {
  name: "SignaturePad",
  components: { VueSignaturePad },

  props: ["index"],
  mounted() {
    
  },
  data() {
    return {
      penColorValue: "#17638d",
      penColors: [
        {
          text: this.$t('azrq'),
          value: "#17638d",
        },
        {
          text: this.$t('aswd'),
          value: "#1c1c1c",
        },
        {
          text: this.$t('akhdhr'),
          value: "#137613",
        },
        {
          text: this.$t('ahmr'),
          value: "#F44336",
        },
      ],
    };
  },
  methods: {
    changePenColor() {
      this.option.penColor = this.penColorValue;
    },
    clear(i) {
      var nameref = `this.$refs.signaturePad.clearSignature()`;
      eval(nameref);
    },
    undo(i) {
      var nameref = `this.$refs.signaturePad.undoSignature()`;
      eval(nameref);
    },
    changeFun() {
      this.$refs.signaturePad.saveSignature();
      this.$emit("input", this.$refs.signaturePad.signatureData);
    },
  },
};
</script>
